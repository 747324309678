import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PostCardCreator from "../components/postCardCreator"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

class AllContentTypesContentfulTemplate extends React.Component {
  render() {      
    const siteTitle = this.props.data.site.siteMetadata.title
    const postsGuias = this.props.data.allContentfulAllContentTypes.edges
    const posts = this.props.data.contentfulAllContentTypes
    let postCounter = 0

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={posts.author}
          description={postsGuias.description || postsGuias.excerpt}
        />
    
        <div className="row" style={{backgroundColor: "#fff"}}>
        <div className="col-12 box-sections">
        <div className="post-content" 
            style={{
              paddingTop: "5vw", 
              paddingBottom: "5vw",
              textAlign: "center"
            }}
           >
            <h1 className="post-content-header" style={{marginTop: "150px", height: "200px",}}>{posts.author}</h1>
          </div>
        <div className="post-feed">
          {postsGuias.map(({ node }) => {
            postCounter++
            return (
              <PostCardCreator
                key={node.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            )
          })}
        </div>
        </div>
      </div>

      </Layout>
    )
  }
}

export default AllContentTypesContentfulTemplate

export const pageQuery = graphql`
  query ContentfulAllContentTypesBySlugs($slug: String!){
    site {
      siteMetadata {
        title
      }
    }
    contentfulAllContentTypes(author: {eq: $slug}) {
      author
      slug
    }
    allContentfulAllContentTypes(filter: {author: {eq: $slug}}) {
      edges{
        node{
          title
          description
          publishedDate
          author
          slug
          image{
            fluid(maxWidth: 2048, quality: 100){
              src
            }
          }
        }
      }
    }
  }
`
